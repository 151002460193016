<template>
  <div class="box">
    <h1>欢迎登录长三角（义乌）生态环境研究中心</h1>
    <div class="card">
      <div class="left">
        <img src="../assets/2.png" width="100%" height="100%" />
      </div>
      <div class="middle">
        <img src="../assets/1.png" width="100%" height="100%" />
      </div>
      <div class="right">
        <div class="input_box">
          <div class="icon">
            <img src="../assets/user.png" width="100%" height="100%" />
          </div>
          <div class="line"></div>
          <div>
            <input
              type="text"
              placeholder="请输入手机号"
              v-model="numPhone"
              maxlength="18"
            />
          </div>
        </div>
        <div class="input_box">
          <div class="icon">
            <img src="../assets/verification.png" width="100%" height="100%" />
          </div>
          <div class="line"></div>
          <div class="inputBtn">
            <div>
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="verification"
              />
            </div>
            <div class="btnV" v-if="!verificationShow" @click="codeBtn">
              获取验证码
            </div>
            <div class="btnV" v-if="verificationShow">
              {{ verificationCode }}s后重新获取
            </div>
          </div>
        </div>
        <div class="input_box">
          <div class="icon">
            <img src="../assets/password.png" width="100%" height="100%" />
          </div>
          <div class="line"></div>
          <div>
            <input
              type="password"
              placeholder="请输入新密码"
              v-model="password"
            />
          </div>
        </div>
        <button class="btn" @click="loginBtn">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { forgotPassword, getCode } from "../api/api";

export default {
  data() {
    return {
      numPhone: "", //手机号
      verification: "", //验证码
      password: "", //密码
      checked: false,
      verificationCode: 60,
      verificationShow: false,
    };
  },
  methods: {
    //点击验证码按钮
    codeBtn() {
      if (!this.numPhone) {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
        });
        return;
      }
      this.verificationShow = true;
      let timer = setInterval(() => {
        if (this.verificationCode > 1) {
          this.verificationCode--;
        } else {
          this.verificationShow = false;
          clearInterval(timer);
          this.verificationCode=60
        }
      }, 1000);
      this.getCode();
    },
    //获取验证码
    async getCode() {
      let that = this;
      let res = await getCode({
        phone: that.numPhone,
      });
      if (res.ResultCode == 0) {
        console.log(res, "res");
      }
    },
    //修改密码
    async getLogin() {
      let that = this;
      let res = await forgotPassword({
        password: that.password,
        username: that.numPhone,
        vercode:that.verification
      });
      if (res.ResultCode == 0) {
        that.$message({
          message: "修改成功",
          type: "success",
        });
        setTimeout(() => {
          that.$router.push("/login");
        }, 1500);
      } else {
        that.$message({
          message: res.ResultInfo,
          type: "error",
        });
      }
    },
    loginBtn() {
      let that = this;
      if (!that.numPhone) {
        that.$message({
          message: "手机号不能为空",
          type: "error",
        });
        return;
      }
      if (!that.verification) {
        that.$message({
          message: "验证码不能为空",
          type: "error",
        });
        return;
      }
      if (!that.password) {
        that.$message({
          message: "密码不能为空",
          type: "error",
        });
        return;
      }
      that.getLogin();
    },
 
    //跳转登录页面
    toLogin(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  background: 100vw;
  height: 100vh;
  background-image: url("../assets/bg.png");
  background-size: 100vw 100vh;
  padding-top: 1px;
  box-sizing: border-box;
  h1 {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-top: 135px;
  }
  .card {
    width: 977px;
    height: 579px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 40px;
    margin-top: 63px;
    background-image: url("../assets/bg1.png");
    background-size: 100%;
    display: flex;
    flex-direction: row;
    .left {
      width: 346px;
      height: 308px;
      margin-top: 117px;
      margin-left: 74px;
      margin-right: 42px;
    }
    .middle {
      width: 1px;
      height: 447px;
      margin-top: 14px;
      margin-right: 46px;
    }
    .right {
      padding-top: 121px;
      .input_box {
        width: 396px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .icon {
          width: 26px;
          height: 26px;
          margin: 12px 14px;
        }
        .line {
          width: 1px;
          height: 24px;
          background: #e5e5e5;
        }
        input {
          border: none;
          margin-left: 14px;
          font-size: 18px;
          outline: none; /*点击之后。不出现边框*/
          width: 300px;
        }
      }
      .inputBtn {
        width: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 230px !important;
        }
      }
      .btnV {
        cursor: pointer;
        width: calc(100% - 230px);
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #397aff;
        box-shadow: 0px 3px 13px 0px rgba(25, 45, 168, 0.28);
        border-radius: 8px;
        border: none;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-right: 10px;
      }
      .btn {
        width: 414px;
        height: 56px;
        background: #397aff;
        box-shadow: 0px 3px 13px 0px rgba(25, 45, 168, 0.28);
        border-radius: 8px;
        border: none;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 34px;
      }
      .bom_title {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        text-align: center;
        margin-top: 35px;
        cursor: pointer;
        span {
          padding-left: 20px;
        }
      }
      .radio_box {
        margin-top: 60px;
      }
    }
  }
}
</style>
